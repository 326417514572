.Filter{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.123);
    border-width: .01em;
    height: fit-content;
    background-color: rgba(245, 245, 245, 1);
}
.Filter .FilterRow{
    width: 100%;
    margin:0;
    padding:0;
}
.Filter .FilterRow p{
    width: 100%;
    margin:0;
    padding:.25em;
}
.Filter .FilterRow :hover{
    cursor:pointer
}