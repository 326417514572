.GPUResults{
    background-color: whitesmoke;
    width: 70%;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.123);
    border-width: .01em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GPUResults .GPUResultsHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.GPUResults .GPUResultsHeader *{
    display: flex;
    flex-direction: row;
    margin-right:.5em;
    margin-left:.5em;
    align-items: center;
}


.GPUResults .GPUResultsHeader{
    width: 100%;
}

.GPUResults .GPUResultsRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}
.GPUResults .GPUResultsRow p{
    text-align: start;
    width: 25%;
}
.GPUResults .GPUResultsRow :hover{
    cursor: pointer;
    background-color: rgba(225, 225, 225, 1);
}

.GPUResultsExtraInformation{
    margin-left: .5em;
    padding-top:.25em;
    padding-bottom:1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
}
.GPUResultsExtraInformation p{
    margin: .5em;
}