.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.App .AppContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 1em;
  margin-bottom: 1em;
}


*{
  margin: 0;
}

hr{
  margin-top: 1em;
  margin-bottom: 1em;
}

.Footer{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: last baseline;
  height: 100%;
}
.Footer :hover{
  cursor: pointer;
}
.InternalFooterDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}